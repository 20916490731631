import React from 'react';
import { BrowserRouter as Router, Routes, Route } from 'react-router-dom'; // Import Route
import 'bootstrap/dist/css/bootstrap.min.css';
import 'bootstrap/dist/js/bootstrap.bundle.min.js';

import Header from './components/Header/Header';
import AuthPage from './components/Authorization/AuthPage';
//import Dashboard from './components/Dashboard/Dashboard';
//import Register from './components/Register/Register';
//import Login from './components/Login/Login';
import HeroSection from './components/HeroSection/HeroSection';
import HowItWorks from './components/HowItWorks/HowItWorks';
import Testimonials from './components/Testimonials/Testimonials';
import FAQ from './components/FAQ/FAQ';
import Footer from './components/Footer/Footer';
// import other components...

//import logo from './logo.svg';
import './App.css';

function HomePage() {
  return (
    <>
      <HeroSection />
      <section id="how-it-works">
        <HowItWorks />
      </section>
      <section id="testimonials">
        <Testimonials />
      </section>
      <section id="FAQ">
        <FAQ />
      </section>
    </>
  );
}



function App() {
  return (
    <Router>
      <div className="App">
        <Header />
        <div className="spacer"></div>
        <main className="container">
          <Routes>
            <Route path="/" element={<HomePage />} />
            <Route path="/auth" element={<AuthPage />} />
          </Routes>
        </main>
        <Footer />
      </div>
    </Router>
  );
}

export default App;
